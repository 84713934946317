import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'
// Components
import { Container, Wrapper, Seo, PageHeader } from '../../components'
// Context
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'

const sharedCss = {
  height: '50px',
  fontWeight: 900,
}

const Header = styled(Container)`
  ${sharedCss};
  background-color: ${({ theme }) => theme.colors.palestinePurple};
  color: ${props => props.theme.colors.white};
`

const ReportCard = styled(Container)`
  ${sharedCss};
  background-color: ${({ theme }) => theme.colors.platinum};
  color: ${props => props.theme.colors.raisinBlack};
  margin-top: 0.5rem;
  cursor: pointer;
`

const StyledAnchor = styled.a`
  color: ${props => props.theme.colors.metallicSeaweed};
  font-weight: 900;
  margin: 1rem;
  cursor: pointer;
`

const Reports = () => {
  const { qUser } = useAuthContext()
  const {
    prismicData: { prismicReports },
  } = usePrismic()

  const asReportUrl = `https://qfunctions-prod.azurewebsites.net/api/GetAutoShipForecastReport?laid=${qUser?.legacyAssociateId}`

  return (
    <>
      <Seo title={prismicReports.report_center} />
      <Wrapper>
        <PageHeader exitRoute="/">{prismicReports.report_center}</PageHeader>
        <>
          <Container direction="column">
            <Header>{prismicReports.quick_reports.toUpperCase()}</Header>
            <ReportCard
              onClick={() => navigate('/reports/amb-volume-tracking')}
            >
              {prismicReports.amb_volume_tracking.toUpperCase()}
            </ReportCard>
            <ReportCard
              onClick={() => navigate('/reports/executive-timeclock')}
            >
              {prismicReports.exec_timeclock.toUpperCase()}
            </ReportCard>
            <ReportCard onClick={() => navigate('/reports/leg-volumes')}>
              {prismicReports.leg_volumes.toUpperCase()}
            </ReportCard>
            <ReportCard onClick={() => navigate('/reports/new-enrollments')}>
              {prismicReports.new_enrollments.toUpperCase()}
            </ReportCard>
            <ReportCard onClick={() => navigate('/reports/personal-customers')}>
              {prismicReports.personal_customers.toUpperCase()}
            </ReportCard>
            <ReportCard onClick={() => navigate('/reports/rank-advancement')}>
              {prismicReports.rank_advancement.toUpperCase()}
            </ReportCard>
            <StyledAnchor href={asReportUrl} target="_blank">
              {prismicReports.subscription_forecast.toUpperCase()}
            </StyledAnchor>
          </Container>
        </>
      </Wrapper>
    </>
  )
}

export default Reports
